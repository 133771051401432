@import 'styles/mixins.scss';
@import 'styles/vars.scss';

// .about-board {
  // margin-top: 58px;
  // max-width: 637px;
  // width: 100%;
  // margin: auto;
  // clip-path: url(#about-content-clippy);
  // position: relative;
  // background: rgba(#2C2830, .8);
  // background-image: url('../../../../assets/images/about/background-board.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
// }
// .about-board__back {
//   position: absolute;
//   left: 0;
//   top: 0;
//   z-index: 2;

//   .svg-fill {
//     fill: #6174FF;
//   }
//   .svg-stroke {
//     stroke: #C42AE8;
    
//   }

//   &,
//   > svg {
//     width: 100%;
//     height: 100%;
//   }
//   svg[data-mobile] {
//     display: none;
//   }
// }
.about-board__content {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-board__text {
  font: 500 16px/1.21 $plusJakarta;
  text-align: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.8);
  
}
.about-board__text-about {
  margin-top: 18px;
}
.about-board__text-title {
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 600;
  position: relative;
}
.about-board__text-list {
  margin-top: 15px;

  li {
    font-size: 15px;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #e7e7e7;
      transform: translateY(-.15em);
      margin-right: 6px;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  li + li {
    margin-top: 5px;
  }
}
.about-board__btn {
  margin-top: 25px;
  // text-transform: uppercase;
}

.about__list{
  display: flex;
  @media (max-width: 1200px){
    flex-wrap: wrap;
    justify-content: center;
  }
}

.about__item{
  position: relative;
  width: 400px;
  padding-top: 400px;
  @media (max-width: 1200px) {
      margin-top: -100px;
    }
}

.about__img {
  position: absolute;
  top: -50px;
  width: 500px;
  height: 500px;
  left: 54%;
  transform: translateX(-50%);
  pointer-events: none;
}

.about__title{
  font-weight: 600;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-family: $clashDisplay;
}

.about__title-block{
  margin-bottom: -80px;
}

.about__text{
  max-width: 270px;
  margin: auto;
  margin-top: 20px;
  font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.6);
    @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
}

@media (max-width: 768px) {
  .about-board {
    margin-top: 32px;
  }
  // .about-board__content {
  //   padding: 45px 50px;
  // }
  .about-board__btn {
    margin-top: 20px;
    font-size: 14px;
    .button-background {
      max-width: 11.125em;
    }
  }
}

@media (max-width: 575px) {
  .about-board {
    max-width: 328px;
    clip-path: url(#about-content-mobile-clippy);
  }
  .about-board__content {
    padding: 49px 13px 50px;
  }
  .about-board__text {
    font-weight: 400;
  }
}

@media (max-width: 376px){
  .about-board__text {
    font-size: 14px;
  }
  .about-board__btn {
    font-size: 12px;
    .button-background {
      max-width: 12.5em;
    }
  }
}

@media (min-height: 768px) 
and (max-height: 900px)
and (max-width: 1400px)
and (min-width: 1200px) {
  .about__content {
    padding: 120px 80px;
  }

  .about__title-block {
    margin-bottom: 10px;
  }

  .about__item {
    width: 300px;
    padding-top: 270px;
    margin-top: -15px;
  }
  
  .about__img {
    width: 300px;
    height: 300px;
  }

  .about-board__btn {
    margin-top: 0;
  }
}