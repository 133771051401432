@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.header__container {
  display: flex;
  justify-content: space-between;
  padding-top: 31px;
}

.header__logo {
  max-width: 85px;
  overflow: hidden;
  &,
  > img {
    width: 100%;
  }
}

.header__burgermenu {
  display: none;
  width: 49px;
  height: 16px;
  position: relative;
  transition: $app-transition;
  transition-property: width, height;
}

.header__burgermenu span {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  transition: $app-transition;
  transition-property: left, top, transform;

  // &:first-child {}
  &:last-child {
    top: 100%;
    transform: translateY(-100%);
  }
}

.home-page.menu-active .header__burgermenu {
  width: 26px;
  height: 26px;

  span {
    left: 50%;
    top: 50%;

    &:first-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:last-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

@media (max-width: 1180px) {
  .header__container {
    padding: 30px 30px 0;
  }
  .header__btn {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .header__logo {
    max-width: 79px;
  }
  .header__btn {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .header__container {
    align-items: center;
  }
  .header__btn {
    display: none;
  }
  .header__burgermenu {
    display: block;
  }
}

@media (max-width: 375px) {
  .header__container {
    padding: 25px 15px 0;
    @media screen and (min-aspect-ratio: 351 / 649) {
      padding: 10px 10px 0;
    }
  }
}

// Animation
.header__logo img {
  transform: translateX(100%);
}
.header__btn {
  transform: scale(0);
  .button-wrapper {
    opacity: 0;
  }
}
.header.animation {
  .header__logo img {
    transition: transform 1s ease-in-out animDelay();
    transform: translate(0);
  }
  .header__btn {
    transition: transform 1s ease-in-out animDelay(.7);
    transform: scale(1);
    .button-wrapper {
      transition: opacity 2.2s ease-in-out animDelay(.7);
      opacity: 1;
    }
  }
}