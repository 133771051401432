.screen-controller {



    // ====================================================================
    // MAIN (1)
    &.-preloading,
    &.-main-screen {
        #{screenClass(1)} {
            @include setActiveScreen;
        }
    }

    // доп стили для анимаций
    #{screenClass(1)} {
        position: relative;
        // &::after {
        //     content: '';
        //     position: absolute;
        //     top: 0; left: 0;
        //     width: 100%;
        //     height: 100%;
        //     opacity: 0;
        //     background: radial-gradient(71.72% 71.72% at 50% 50%, #4349CE 13.5%, rgba(151, 33, 170, 0) 100%);
        //     pointer-events: none;
        //     user-select: none;
        //     z-index: 10000;
        //     backdrop-filter: blur(100px);
        // }
        > * {
            transition: transform 3s ease;
        }
    }
    

    // --------------> FRONT
    &.-main-screen.-between-front-screen {
        #{screenClass(1)} {
            // &::after { 
            //     opacity: 1; 
            //     transition: 1s opacity 2.75s ease;
            // }
            > *:not(.menu, .scene) {
                transform: matrix(1.4, 0, 0, 1.4, -0.7, 0);
                transition: transform 6s ease;
            }
        }
    }


    // ====================================================================
    // ABOUT (2)
    #{screenClass(2)} {
        opacity: 0;
    }
    &.-about-screen {
        #{screenClass(2)} {
            @include setActiveScreen;
            opacity: 1;
        }
    }

    // доп стили для анимаций
    #{screenClass(2)} {
        .about__content { perspective: 1000px; }
        .section-title {
            transition: 1.5s transform ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
        .about-board {
            transition: 1.5s transform .5s ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
    }
    // 

    &.-about-screen {
        // также добавляем эти стили, чтобы анимировалось все по обратному пути при обратном скролле
        #{screenClass(1)} {
            // &::after { opacity: 0; }
            > *:not(.menu, .scene) {
                transform: matrix(1.4, 0, 0, 1.4, -0.7, 0);
            }
        }
        // 

        #{screenClass(2)} {
            .section-title, .about-board {
                transform: translate3d(0, 0, 0) rotateX(0);
            } 
        }
    }

    // --------------> BACK
    &.-about-screen.-between-back-screen {

    }



}