.screen-controller {
  &.-advisors-screen {
      #{screenClass(8)} {
          @include setActiveScreen;
      }
  }

  // // 
  #{screenClass(8)} {
      .advisors__content, .swiper-wrapper {
          perspective: 1000px;
      }
      .swiper-wrapper > * {
          transform: translate3d(0, 200px, 600px) rotateX(45deg);
          opacity: 0;
      }
      .section-title {
          transition: 1s ease;
          transition-property: transform, opacity;
          transform: translate3d(0, 300px, 1000px) rotateX(45deg);
          opacity: 0;
      }
  }
  &.-advisors-screen {
      #{screenClass(8)} {
          .section-title, .swiper-wrapper > * {
              transform: translate3d(0, 0, 0) rotateX(0);
              opacity: 1;
          }
          @for $i from 1 through 20 {
              .swiper-wrapper > :nth-child(#{$i}) {
                  transition: .5s transform #{$i / 5}s ease, .5s opacity #{$i / 5}s;
              }
          }
      }
  }
  // // 

  // --------------> FRONT
  &.-team-screen.-between-front-screen {
      #{screenClass(7)} {
          opacity: 0;
          transition: opacity 2s ease;
      }
      #{screenClass(8)} {
          opacity: 1;
          transition: 1s opacity 1s ease;
          @include setActiveScreen;
      }
  }

  // --------------> BACK
  &.-advisors-screen.-between-back-screen {
     #{screenClass(7)} {
          opacity: 0;
          transition: opacity 2s ease;
      }
      #{screenClass(8)} {
          opacity: 1;
          transition: 1s opacity 1s ease;
          @include setActiveScreen;
      }
  }
}