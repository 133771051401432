$screens: (
    1: '.hero',
    2: '.about',
    3: '.target',
    4: '.token',
    5: '.works',
    6: '.partners',
    7: '.team',
    8: '.advisors',
    9: '.roadmap'
);

@function screenClass($id) {
    @return map-get($map: $screens, $key: $id);
}

@mixin setActiveScreen {
    visibility: visible;
    user-select: auto;
    pointer-events: all;
    opacity: 1;
}

// ========================================
@import "./MainAbout";
@import "./AboutTarget";
@import "./TargetToken";
@import "./TokenWorks";
@import "./WorksPartners";
@import "./PartnersTeam";
@import "./TeamAdvisors";
@import "./AdvisorsRoadmap";