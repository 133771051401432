@import 'styles/mixins.scss';
@import 'styles/vars.scss';
.team-card__body:hover {
  box-shadow: 0 0 11px #cf419b;
}
.team {
  min-height: 100vh;
  overflow: hidden;
  background-image: url('../../../assets/images/team/team_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
}
.team__content {
  padding: 35px 18px 119.5px;
}
.team__box {
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team__swiper {
  max-width: 1134px;
  width: 100%;
}
.team__slide {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  position: relative;
  padding: 0 10px;
  &:first-child {
    .team__separator {
      display: none;
    }
  }
}
.team__slide:nth-child(odd) {
  display: flex;
  flex-flow: wrap;
  .team-card__header{
    order: 1;
  }
  .team-card__body{
    order: 0;
  }
  }
.team__separator {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
}
/* 
  Card
*/
@media (max-width: 1360px) {
  .team__content {
    padding-top: 100px;
  }
}
@media (max-width: 1120px) {
  .team__frame {
    .keyboard-right,
    .keyboard-left {
      fill-opacity: 1;
    }
  }
  .team__content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .team__box {
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .team__swiper {
    max-width: 90%;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 768px) {
  .team__title {
    margin: 0 auto;
    max-width: 400px;
    span {
      font-size: 38px;
    }
  }
  .team__box {
    margin-top: 32px;
    justify-content: center;
  }
  .team__swiper {
    margin: 0;
  }
}
@media (max-width: 465px) {
  .team__title span {
    font-size: 35px;
  }
}
@media (max-width: 534px) {
  .team__swiper {
    max-width: 280px;
  }
}
@media (max-width: 376px) {
  .team__title span {
    font-size: 30px;
  }
  .team__swiper {
    max-width: 250px;
  }
}
.team-gradient{
  position: absolute;
  bottom: 0px;
  left:0;
}

.team-gradient__top{
  position: absolute;
  top: -10%;
  right: 0px;
  transform: rotate(180deg);
}

@media (max-width: 1600px) {
  .team__content {
    padding-bottom: 100px;
  }

  .team__box {
    margin-top: 30px;
  }
}

button.team__prev.team__nav {
  position: absolute;
  left: 0em;
  top: 28em;
}

button.team__next.team__nav {
  position: absolute;
  right: 0;
  top: 28em;
}
@media (max-width: 1500px) {
  button.team__prev.team__nav {
    top: 20.4em;
  }
  
  button.team__next.team__nav {
    top: 20.4em;
  }
}

@media (max-width: 850px) {
  button.team__prev.team__nav {
    top: 27.4em;
  }
  
  button.team__next.team__nav {
    top: 27.4em;
  }
}