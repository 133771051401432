@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.content-frame-title {
  position: absolute;
  left: 10px;
  top: -5px;
  z-index: 15;
  display: flex;
  align-items: center;
}
.content-frame-title__wheel {
  max-width: 105px;
  width: 100%;
  position: relative;
  cursor: pointer;

  svg {
    width: 100%;
    position: relative;
    z-index: 1;

    path {
      transform-origin: center;
    }
    path.svg-wheel-1 {
      animation: wheel_rotation 20s linear infinite;
    }
    path.svg-wheel-2 {
      animation: wheel_rotation 17s linear infinite reverse;
    }
  }
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    z-index: 2;
    max-width: 45%;
  }
}

.content-frame-title__wheel-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding-bottom: 5px;

  color: #fff;
  font-size: 11px;
  font-weight: 500;
  font: 500 11px $neueMachina;
  text-transform: uppercase;

  &-points {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15px;
    width: 100%;
    margin-bottom: 10px;
    &-item {
      max-width: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      &:nth-child(1) {
        & span:nth-child(1) {
          background-color: #BBC2FF;
        }
      }

      & span {
        width: 3px;
        height: 3px;
        background-color: #fff;
        border-radius: 100%;
      }
    }
  }
}

.content-frame-title__title {
  min-width: 156px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(#fff, 0.3);
  position: relative;

  margin-left: 12.25px;
  font: 500 15px/1.1 $clashDisplay;
  letter-spacing: 0.03em;
  color: rgba(#fff, 0.5);

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 17px;
    background: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBvcGFjaXR5PSIwLjMiIGQ9Ik0xIDFMMTUgMTYuNSIgc3Ryb2tlPSJ3aGl0ZSIvPg0KPC9zdmc+DQo=);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    left: calc(100% - 1px);
    top: calc(100% - 1px);
  }
}
@keyframes wheel_rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 575px) {
  .content-frame-title {
    left: 5%;
    top: -10px;
    align-items: flex-end;
  }
  .content-frame-title__wheel {
    max-width: 70px;
  }
  .content-frame-title__title {
    margin-left: -20px;
    padding-left: 40px;
    transform: translateY(-8px);
  }
}
