@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.works {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.works__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    // filter: grayscale(1);
  }
}

// .works__backgrounds {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   >div {
//     position: absolute;
//     // left: 50%;
//     // top: 50%;
//     overflow: hidden;
//     margin: 0 auto;
//     // max-width: 909px;
//     width: 100%;
//     height: 100%;
//     // transform: translate(-50%, -47%);

//     >* {
//       position: absolute;
//       min-width: 100%;
//       min-height: 100%;
//       width: auto;
//       height: auto;
//       top: 0;
//       left: 50%;
//       transform: translateX(-50%);
//     }
//   }
// }

@keyframes changeLayer {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes changeLayer2 {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.works__container {
  position: relative;
  z-index: 5;
}

// .works__frame {
//   padding-top: 100px;
// }
.works__content {
  padding: 100px 40px 113px;
  text-align: center;
}

.works__title {
  margin: 100px auto 20px;
}

.works__wrapper svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &[data-mobile] {
    display: none;
  }

  .svg-fill {
    fill: #c42ae8;
  }

  .svg-stroke {
    stroke: #6174ff;
  }
}

.works-board__text {
  font: 500 15px/1.21 $plusJakarta;
  text-align: center;
  letter-spacing: 0.03em;
  line-height: 2;
  color: #e7e7e7;
}

.works-board__text-list {
  margin: 20px auto 50px;
  max-width: 650px;
  text-align: center;
  display: inline-block;

  li {
    font-size: 15px;
    text-align: center;

    &+li {
      margin-top: 5px;
    }
  }

  &::marker {
    // content: "";
    display: none;

  }

  +li {
    margin-top: 5px;
  }
}

.works__text-p {
  +.works__text-p {
    margin-top: 12px;
  }
}

.works__btn {
  margin-top: 41px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  display: block;
}

// Marquee
.works-marquee {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  overflow: hidden;
  transform: rotate(7.5deg) skewX(7.5deg);
}

.works-marquee__col {
  display: flex;
  flex-shrink: 0;
  animation: moving-to-left 30s linear infinite;

  +.works-marquee__col {
    margin-left: -30px;
  }
}

.works-marquee__item {
  padding: 0 10px;
  transform: skewX(-15deg);
  display: flex;

  +.works-marquee__item {
    margin-left: -30px;
  }

  img {
    max-width: 291px;
    width: 100%;
  }
}

@keyframes moving-to-left {
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 991px) {
  .works-marquee__item img {
    max-width: 220px;
  }
}

@media (max-width: 768px) {
  .works-board__text-list {
    margin: 20px auto 20px;

    li {
      font-size: 10px;

    }
  }

  // li {
  //     font-size: 12px;
  //   }
  .works__name {
    // font-size: 30px;
    margin-top: 25px;
  }

  .works__content {
    padding-top: 110px;
  }

  .works__title {
    // max-width: 373px;
    // margin-top: 100px;
    margin-bottom: -50px;
    // width: 80px;
  }

  .works__wrapper {
    margin-top: 32px;
    max-width: 500px;
    // clip-path: url(#about-content-mobile-clippy);
  }

  .works__wrapper svg {
    &[data-mobile] {
      display: block;
    }

    &[data-desktop] {
      display: none;
    }
  }

  .works__wrapper p {
    font-size: 14px;
  }

  .works__btn {
    margin-top: 24px;
    font-size: 13.9px;
  }
}
// @media (max-width: 620px) {
//   .works__name{
//     width: 80px;
//   }
// }


@media (max-width: 465px) {
  .works__content {
    padding-left: 25px;
    padding-right: 25px;
  }

  // .works__name {
  //   width: 150px;
  // }
  .works__frame{
    margin-top: -100px;
    padding: 46.5px 20px 68.5px;

    & .content-frame__wrapper {
      padding-bottom: 40px;
    }
  }
  .works__wrapper {
    padding: 80px 13px 49px;
  }

  .works__wrapper p {
    display: inline;

    +p {
      margin-top: 0;
    }
  }

  .works__frame {

    .content-frame__background {
      display: none;
    }
    .content-frame-title{
      display: none;
    }
    
  }

  .works-board__text-list {
    li {
      font-size: 14px;
      line-height: 22px;

      &+li {
        margin-top: 0px;
      }
    }
  }

  .works__btn {
    margin-top: 50px;
    margin-bottom: 200px;
  }
}

@media (max-width: 375px) {
  .works__content {
    padding-left: 8px;
    padding-right: 8px;
  }

  .works__wrapper p {
    font-size: 12.5px;
  }
}

.works-board {
  margin-top: 58px;
  max-width: 637px;
  width: 100%;
  margin: auto;
  clip-path: url(#about-content-clippy);
  position: relative;
  background: rgba(#2C2830, .8);
  background-image: url('../../../assets/images/works/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  backdrop-filter: blur(20px);

  @media (max-width: 865px) {
    width: 85%;
    margin-top: 80px;
  }

  @media (max-width: 465px) {
    width: 100%;
    clip-path: url(#about-content-mobile-clippy);
  }
}

.works-board__content {
  @media (max-width: 865px) {
    padding: 25px 20px 20px;
  }
}

.works-board__back {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  .svg-fill {
    fill: #6174FF;
  }

  .svg-stroke {
    stroke: #C42AE8;

  }

  &,
  >svg {
    width: 100%;
    height: 100%;
  }

  svg[data-mobile] {
    display: none;
  }

  @media (max-width: 465px) {
    svg[data-desktop] {
      display: none;
    }
    svg[data-mobile] {
      display: block;
    }
  }
}

.works__gradientFirst {
  position: absolute;
  z-index: 1;
  width: 1250px;
  height: 1200px;
  right: 50%;
  top: 90%;
  background: #0011E0;
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.works__gradientSecond {
  z-index: 1;
  position: absolute;
  width: 1350px;
  height: 1350px;
  top: 120%;
  left: 70%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.works-gradient {
  position: absolute;
  bottom: -10%;
  z-index: 2;
  left: 0%
}

.works-gradient__top {
  position: absolute;
  top: -10%;
  right: 0px;
  transform: rotate(180deg);
  z-index: 2;
}

.works__btn {
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .works__frame {
    padding: 0;
  }

  .works__title {
    margin-top: 50px;
  }

  .works-board {
    margin-top: -20px;
  }

  .works-board__text {
    font-size: 10px;
  }

  .works__btn {
    margin-top: 0;
  }
}

