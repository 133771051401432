@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.button {
  font-size: 16px;
  display: inline-block;
}
.button-wrapper {
  min-width: 14.0625em;
  min-height: 4.875em;
  padding: 1.0625em;
  position: relative;

  display: flex;
}
.button-border {
  position: absolute;
  left: 0;
  top: 0;
  // z-index: 999;
  width: 100%;
  height: 100%;
}
.button-border span {
  width: 1.0625em;
  height: 1.0625em;
  border-left: 0.3em solid #fff;
  border-bottom: 0.4em solid #fff;
  filter: drop-shadow(0px 0px 0.35em #09a7b8);
  position: absolute;

  &:nth-child(1) {
    top: 0;
    left: 0;
    transform: rotate(90deg);
  }
  &:nth-child(2) {
    top: 0;
    right: 0;
    transform: rotate(-90deg) scaleX(-1);
  }
  &:nth-child(3) {
    bottom: 0;
    left: 0;
    transform: rotate(90deg) scaleX(-1);
  }
  &:nth-child(4) {
    bottom: 0;
    right: 0;
    transform: rotate(-90deg);
  }
}

.button-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  max-width: 12em;
}

.button-content {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  span {
    font: 700 17px/1.1662 $clashDisplay;
    color: #000;
  }
  svg {
    margin-left: 10px;
    max-width: 11px;
    width: 100%;
    .svg-stroke {
      stroke: #000;
    }
  }
}

// Modificator

.button {
  &.white {
    .button-background {
      width: 100%;
      transform: translate(-50%, -42%);
      .svg-fill {
        fill: #fff;
      }
    }
  }
  &.gray {
    min-height: 5.7em;
    margin-left: 1.5em;

    .button-border {
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 3em);
      span {
        border-left-width: 0.4em;
      }
    }
    .button-background {
      max-width: 100%;
      max-height: 4.2em;
      width: 100%;
    }
    .button-content {
      span {
        color: #fff;
      }
      svg .svg-stroke {
        stroke: #fff;
      }
    }
  }
}
