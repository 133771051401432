@import 'styles/mixins.scss';
@import 'styles/vars.scss';

html, body {
  background: $clr-black;
}

.app {
  background: $clr-black;
  height: 100%;
} 

html, body, .app, .home-page {
  position: relative;
  overflow: hidden;
  height: 100%;
}