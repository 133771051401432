@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.hero-content__wrapper {
  position: relative;
  z-index: 150;
  width: 100%;
  flex-shrink: 0;
  margin: auto;
}
.hero-content__container {
  display: flex;
  // padding-top: 110px;
  max-width: 100%;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 1452.5px;
}
.hero-content__play {
  font: 500 187px/1.1 $clashDisplay;
  background: linear-gradient(180deg, #fff 37.53%, rgba(255, 255, 255, 0) 99.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  --text-fill-color: transparent;
  letter-spacing: 0.03em;
  opacity: 0.6;
  text-transform: uppercase;

  &-mobile {
    background: none;
    opacity: .8;

    &_item {
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 99.04%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      --text-fill-color: transparent;

      &-middle {
        background-color: #fff;
        opacity: .8;
      }
    }
  }
}

.hero-content__play {
  font: 500 187px/1.1 $clashDisplay;
  max-width: 550px;
  text-align: left;

  @media (max-width: 1455px) {
    font-size: 12.5vw;
    margin-top: auto;
    margin-bottom: auto;
  }

  @media (max-width: 1200px) {
    font-size: 12.7vw;
  }
}
.hero-content {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero-content__introduce {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  z-index: 1001;
  @media (max-width: 595px) {
    @media screen and (min-aspect-ratio: 351 / 649) {
      // margin-top: -60px;
    }
  }
}
.hero-content__suptitle {
  font: 400 14px/1.1 $plusJakarta;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.hero-content__title {
  margin-top: 15px;
  font: 600 45px/1.1 $clashDisplay;
  text-transform: uppercase;
  letter-spacing: 0.225em;
  background: linear-gradient(
    270.07deg,
    #f8fdfc 6.06%,
    #bac1ff 46.91%,
    #ffffff 96.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  --text-fill-color: transparent;
}
.hero-content__about {
  // max-width: 257px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 350px;

  @media (max-height: 1200px){
    margin-left: 0;
    margin-top: 30px;
  }

}
.hero-content__description {
  font: 600 22px/1.21 $clashDisplay;
  // color: rgba(209, 209, 209, 0.76);
  line-height: 24px;
  letter-spacing: 0.225em;
  max-width: 320px;
  margin-top: 30px;
  background: linear-gradient(270.07deg, #F8FDFC 6.06%, #BAC1FF 46.91%, #FFFFFF 96.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    --text-fill-color: transparent;
  // margin-bottom: 45px;
}
.hero-content__btn {
  margin-top: 40px;
  font-size: 10.07px;
  @media screen and (min-aspect-ratio: 351 / 649) {
    margin-right: 25px;
  }
  @media (max-width: 595px) {
    @media screen and (min-aspect-ratio: 351 / 649) {
      margin-right: 25px;
    }
  }
}
.hero-content__play-mobile {
  display: none;
  font-size: 100px;
  width: min-content;
}

@media (max-width: 991px) {
  .hero {
    display: flex;
    align-items: center;
  }
  .hero-content__container {
    padding-top: 0;
  }
  .hero-content__title {
    font-size: 35px;
  }
  .hero-content__btn {
    margin-top: 20px;
  }
}

@media (max-width: 900px) {
  .hero-content__container {
    padding: 0 80px;
  }
  .hero-content__play {
    display: none;
  }
  .hero-content__play-mobile {
    display: block;
  }
  .hero-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0;
  }
  .hero-content__title {
    font-size: 45px;
  }
  .hero-content__about {
    padding-left: 0;
  }
}

@media (max-width: 900px){
  @media (max-height: 810px) {
    .hero-content__suptitle {
      font-size: 12px;
    }
    .hero-content__title {
      margin-top: 8px;
      font-size: 30px;
      max-width: 270px;
    }
    .hero-content__play-mobile {
      line-height: 1;
    }
    .hero-content__btn {
      font-size: 9px;
    }
  }

  @media (max-height: 640px){
    .hero-content__title {
      font-size: 22px;
    }
    .hero-content__play-mobile {
      font-size: 75px;
    }
    .hero-content__description {
      font-size: 12px;
      max-width: 220px;
    }
    .hero-content__btn {
      font-size: 7px;

      .button-content span {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 768px){
  .hero-content__container {
    padding: 0 40px;
  }
}

@media (max-width: 360px){
  .hero-content__container {
    padding: 0 15px;
  }
}

.hero-content__wrap{
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 320px;

}
.hero-content__list{
  height: 240px;
  margin-left: 100px;
}
@media (max-width: 990px) {
  .hero-content__list {
    display: none;
  }
}

.hero-content__item{
margin-bottom: 10px;
}

.hero-content__link{
  fill: #fff;
  fill-opacity: 0.4;
}
.hero-content__link:hover{
  fill-opacity: 1;
}
.hero-incubation{
   // background: #212121;
   border-radius: 5px;
   display: flex;
   align-items: center;
   // padding-left: 20px;
   padding-right: 20px;
   margin-top: 20px;
}
.hero-incubation__first{
  font-family: $lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding-right: 10px;
}
.hero-incubation__img{
  margin: 7px;
}
.hero-polygon{
  // background: #212121;
  border-radius: 5px;
  display: flex;
  align-items: center;
  // padding-left: 20px;
  margin-bottom: 2%;
}
.hero-polygon__first{
  font-family: $lato;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding-right: 10px;
}
.hero-polygon__img{
  margin: 7px;
}
// .hero-polygon__second{
//   color: #fff;
// }
// @media (min-width: 992px){
//   .hero-content__container{
//     margin-bottom: 10%;
//     margin-top: 10%;
//   }
// }