.screen-controller {
    &.-target-screen {
        #{screenClass(3)} {
            @include setActiveScreen;
            opacity: 1;
        }
    }

    &.-target-screen {
        .target-card {
            opacity: 1;
            transition: opacity 1s ease-in-out;
        
            &_1 {
              transition-delay: animDelay(0.2);
            }
            &_2 {
              transition-delay: animDelay();
            }
            &_3 {
              transition-delay: animDelay(0.35);
            }
          }
          .target-hand__container {
            transform: translateY(0);
            transition: transform 1s ease-in-out;
          }
    }

    // --------------> FRONT
    &.-about-screen.-between-front-screen {
        #{screenClass(2)} {
            opacity: 0;
            transition: opacity 2s ease;
        }
        #{screenClass(3)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
    }

    // --------------> BACK
    &.-target-screen, &.-about-screen {
        #{screenClass(3)} {
            .target__container {
                transform: none;
            }
        }
    }
    &.-target-screen.-between-back-screen {
        #{screenClass(2)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
        #{screenClass(3)} {
            opacity: 0 !important;
            transition: opacity 2s ease;
        }
        .target-card {
            opacity: 0 !important;
        }
        .target-hand__container {
            transform: translate(50%, 100%) !important;
        }
    }
    
}