@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.popup-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden auto;

  padding: 25px;

  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(13px);

  display: flex;
  flex-direction: column;

  &.menu-desk-box {
    padding: 0px;

    @media(max-width: 998px) {
      z-index: -500;
    }
  }

  &.menu-desk-box .popup {
    max-width: 100%;
  }

  &.menu-desk-box .popup .popup__wrapper {
    padding: 0;
    clip-path: none;
    background-image: none;
  }

  &.menu-desk-box .popup .popup__close, &.menu-desk-box .popup .popup__wrapper .popup__frame {
    display: none;
  }
}
.popup {
  margin: auto;
  max-width: 1221px;
  width: 100%;
  position: relative;
}
.popup__wrapper {
  position: relative;
  width: 100%;
  padding: 69px 25px;
  clip-path: url(#about-content-clippy);
  background: rgba(44, 40, 48, 0);
  background-image: url('../../assets/images/popupbox/background-board.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.popup__close {
  position: absolute;
  right: 60px;
  top: 60px;
  z-index: 5;
  width: 23px;

  svg {
    width: 100%;
    height: 100%;
  }
}
.popup__frame {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  &[data-mobile] {
    display: none;
  }

  .svg-fill {
    fill: #c42ae8;
  }
  .svg-stroke {
    stroke: #6174ff;
  }
}
.popup__content {
  position: relative;
  z-index: 4;

  display: flex;
  flex-direction: column;
}

.popup__title {
  display: flex;
  justify-content: center;
  text-align: center;

  font: 600 45px/1.1 $clashDisplay;

  span {
    background: linear-gradient(
      270.07deg,
      #f8fdfc 6.06%,
      #bac1ff 46.91%,
      #ffffff 96.31%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    --text-fill-color: transparent;
    text-transform: uppercase;
  }
}
.popup__text {
  margin-top: 15px;
  font: 500 16px/1.21 $plusJakarta;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  letter-spacing: 0.03em;
}
.popup__form {
  max-width: 1020px;
  width: 100%;
  margin: 44px auto 0;

  display: flex;
  flex-direction: column;
}
.popup__form-row {
  display: flex;
  justify-content: space-between;

  + .popup__form-row {
    margin-top: 36px;
  }
}
.popup__form-input {
  max-width: 480px;
  width: calc(50% - 15px);
}
.popup__form-submit {
  margin: 40px auto 0;
  text-transform: uppercase;
  .button-content svg {
    display: none;
  }
}
// Animation
.popup-box-enter {
  opacity: 0;
}
.popup-box-enter-active {
  transition: opacity 700ms ease-in;
  opacity: 1;
}
.popup-box-exit {
  opacity: 1;
}
.popup-box-exit-active {
  transition: opacity 700ms ease-in;
  opacity: 0;
}

@media (max-width: 991px) {
  .popup__close {
    right: 40px;
    top: 40px;
  }
  .popup__title {
    font-size: 33px;
  }
  .popup__form-input {
    .ui-input__title {
      font-size: 19px;
    }
    .ui-input__title + .ui-input__field {
      margin-top: 10px;
    }
    .ui-input__field input,
    .ui-select__field-btn {
      padding: 20px;
    }
  }
}

@media (max-width: 768px) {
  .popup__wrapper {
    clip-path: url(#about-content-mobile-clippy);
  }
  .popup__close {
    right: 0;
    top: 5px;
  }
  .popup__title {
    font-size: 4.6vw;
  }
  .popup__frame {
    &[data-mobile] {
      display: block;
    }
    &[data-desktop] {
      display: none;
    }
  }

  .popup__form-input {
    width: calc(50% - 8px);
    .ui-input__field input {
      font-size: 13px;
    }
  }
}

@media (max-width: 565px) {
  .popup-box {
    padding: 10px;
  }
  .popup__wrapper {
    padding: 41px 0;
  }
  .popup__text {
    font-size: 13px;
  }
  .popup__form {
    margin-top: 25px;
  }
  .popup__form-row {
    padding: 0 30px;
    flex-direction: column;

    + .popup__form-row {
      margin-top: 25px;
    }
  }
  .popup__form-input {
    max-width: 100%;
    width: 100%;

    .ui-input__title {
      font-size: 16px;
    }

    .ui-input__field input,
    .ui-select__field-btn {
      padding: 16px;
    }

    + .popup__form-input {
      margin-top: 25px;
    }
  }
}

@media (max-width: 375px) {
  .popup__title {
    font-size: 15px;
  }
  .popup__close {
    width: 17px;
  }
  .popup__form-submit {
    font-size: 13px;
  }
  .popup__form-row {
    padding: 0 20px;
  }
  .ui-input__title + .ui-input__field {
    margin-top: 6px;
  }
  .popup__form-input {
    .ui-input__title {
      font-size: 14px;
    }
    
    .ui-input__field input,
    .ui-select__field-btn {
      padding: 13px;
    }

    + .popup__form-input {
      margin-top: 15px;
    }
  }
  .popup__form-row + .popup__form-row {
    margin-top: 15px;
  }
}
