@import 'styles/mixins.scss';
@import 'styles/vars.scss';

$hover-transition: 0.4s ease-in-out;
.team-card {
  max-width: 188px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  gap: 14px;
}

.team-card__header {
  display: flex;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: auto;
  }

  &::before,
  .team-card__header-block {
    background: #151515;
    border: 2px solid #313131;
  }
}
.team-card__header-block {
  margin-left: 7px;
  padding: 6x 4px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-height: 54px;
  position: relative;

  &::before {
    content: '';
    display: block;
    opacity: 0;
    flex: 0 1 10px;
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 100%;
    background: #6174ff;
    position: absolute;
    top: 0;
    left: 7px;
    z-index: 1;
  }
}
.team-card__header-content {
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}
.team-card__header-name {
  font: 600 14px/1.1 $clashDisplay;
  letter-spacing: 0.03em;

  background: linear-gradient(
    270.07deg,
    #f8fdfc 6.06%,
    #bac1ff 46.91%,
    #ffffff 96.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  --text-fill-color: transparent;
}
.team-card__header-prof {
  font: 500 10px/1.21 $plusJakarta;
  letter-spacing: 0.03em;
  color: #d1d1d1;
  padding-left: 6px;
}
.team-card__body {
  border: 2px solid #3e3e3e;
  min-height: 442px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
}
.team-card__member {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  filter: grayscale(1);
  transition: filter $hover-transition;
}
.team-card__text {
  padding: 19px 14px 30px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(110%);
  z-index: 4;
  border-top: 1px solid #6174ff;
  backdrop-filter: blur(25px);
  font: 400 10px/1.21 $plusJakarta;
  color: #fff;
  transition: transform $hover-transition;
}
.team-card__trapezoid {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 1px);
  transform: translateX(-50%);
  width: 30px;
  height: 6px;
  background: #6174ff;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    border: 3px solid transparent;
    border-bottom: 3px solid #6174ff;
  }

  &::before {
    border-left: 3px solid #6174ff;
    left: 100%;
  }

  &::after {
    border-right: 3px solid #6174ff;
    right: 100%;
  }
}
.team-card__twitter-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  max-width: 35px;
  transform: translateX(100%);
  transition: transform $hover-transition;

  svg {
    max-width: 100%;
    width: 100%;
  }
}

// Hover
.team-card__body:hover {
  .team-card__twitter-btn {
    transform: translate(0);
  }
  .team-card__member {
    filter: grayscale(0);
  }
  .team-card__text {
    transform: translateY(0);
  }
}

@media (max-width: 1600px) {
  .team-card__body {
    height: 300px;
    min-height: auto;
  }
  .team-card__member {
    object-position: top;
  }
}
