@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.contact-form__text {
  margin: 15px auto 0;
  max-width: 343px;
}

@media (max-width: 565px){
  .contact-form__text {
    max-width: 250px;
  }
}