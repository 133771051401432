.centered {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slider-slide {
  display: flex;
  justify-content: center;
  span {
    transform: translateX(0.235em);
    font: 600 45px/1.1 $clashDisplay;
    letter-spacing: 0.47em;
    text-transform: uppercase;
    text-align: center;

    background: linear-gradient(
      270.07deg,
      #f8fdfc 6.06%,
      #bac1ff 46.91%,
      #ffffff 96.31%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
