@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.complete-form__title {
  transform: translateX();
  letter-spacing: 0.47em;
  max-width: 890px;
  margin: 0 auto;
  padding-left: 0.47em;
}
.complete-form__text {
  margin-top: 15px;
}
.complete-form__eye {
  margin: 40px auto 0;
  max-width: 360px;
  width: 100%;
  // background: rgba(44, 40, 48, 0.9);
  img {
    width: 100%;
    mix-blend-mode: lighten
  }
}
.complete-form__btn {
  margin: 57px auto 0;
  text-transform: uppercase;

  .button-content svg {
    display: none;
  }
}

@media (max-width: 565px) {
  .popup.--complete {
    .popup__wrapper {
      padding: 100px 0;
    }
    .popup__title {
      max-width: 380px;
    }
  }
}

@media (max-width: 376px) {
  .popup {
    .complete-form__eye {
      margin-top: 20px;
      max-width: 240px;
    }
    .complete-form__btn {
      margin-top: 25px;
      font-size: 13px;
      .button-content span {
        font-size: 15px;
      }
    }
  }
  .popup.--complete {
    .popup__wrapper {
      padding: 70px 0;
    }
  }
}

