@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.intro {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  background: #000;

  display: flex;
  align-items: center;
  justify-content: center;
}
.intro__canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.intro__main {
  position: relative;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro__main img {
  max-width: 215px;
  width: 100%;
}

.intro__main p {
  margin-top: 100px;
  font: 600 45px/1.1 $clashDisplay;
  letter-spacing: 0.285em;
  text-transform: uppercase;

  background: linear-gradient(
    270.07deg,
    #f8fdfc 6.06%,
    #bac1ff 46.91%,
    #ffffff 96.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media (max-width: 768px){
  .intro__main p {
    margin-top: 52px;
    font-size: 30px;
  }
}
@media (max-width: 375px){
  .intro__main img {
    max-width: 180px;
  }
  .intro__main p {
    margin-top: 25px;
    font-size: 25px;
  }
}

.intro-enter,
.intro-enter-active {
  opacity: 1;
}
.intro-exit {
  opacity: 1;
}
.intro-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}