@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.home-page {
  background: #141415;
  // background-image: url('../../assets/images/background.jpg');
  // background-size: 100% 100vh;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  // height: 100%;
}