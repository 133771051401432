button{
    cursor: pointer;
    display: block;
    border: none;
    font-family: inherit;
    background: none;
    padding: 0;
    margin: 0;  
}
p{
    margin: 0;
    padding: 0;
}
ul{
    list-style: none;
    padding-inline-start: 0;
}
input:focus, button:focus, textarea:focus{
    outline: none;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
}
a{
    display: block;
    color: inherit;
    text-decoration: none;
}
input{
    font-family: inherit;
}
img{
    display: block;
    max-width: 100%;
}
span{
    font: inherit;
}