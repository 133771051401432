@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.partners__slider {
  // margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}
button.backers_next {
  position: absolute;
    color: white;
    right: -1%;
    rotate: 90deg;
    font-size: 20px;
}
.partners__nav {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  position: relative;
  z-index: 5;

  svg {
    max-width: 20px;
    width: 100%;
    flex-shrink: 0;
  }
  span {
    display: none;
    // writing-mode: vertical-rl;
    // font: 600 25px/1.1 $clashDisplay;
    // letter-spacing: 0.47em;
    // text-transform: uppercase;
    // transform: translateY(.47em);

    // background: linear-gradient(
    //   270.07deg,
    //   #f8fdfc 6.06%,
    //   #bac1ff 46.91%,
    //   #ffffff 96.31%
    // );
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // --text-fill-color: transparent;
  }

  svg + span,
  span + svg {
    margin-left: 27px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    @media (max-width: 768px){
      display: none;
    }
  }
}
.partners__nav_prev {
  svg {
    transform: scaleX(-1);
    margin-left: -5px;
  }
  span {
    transform: rotate(180deg) translateY(.47em);
  }
}
// .partners__nav_next {
// }
.partners__labels {
  margin-top: 60px;
  max-width: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.partners__swiper {
  &_main {
    max-width: 1272px;
  }
  // &_child {
  // }
}

.partners__slide {
  &_main {
    padding: 0 20px;
    // &:nth-child(2) {
    //   .partners-card__back {
    //     // background-color: #000000;
    //   }
    // }
  }
  // &_child {
  // }
}
.partners__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  justify-content: center;
}

@media (max-width: 991px) {
  .partners__nav {
    svg + span,
    span + svg {
      margin-left: 10px;
    }
    span {
      font-size: 20px;
    }
  }
}
@media (max-width: 768px){
  .partners__nav {
    span {
       display: none;
    }
    svg + span,
    span + svg {
      margin: 0;
    }
    svg {
      max-width: 24px;
      min-width: 24px;
    }
  }
  .partners__labels {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (max-width: 465px){
  .partners__nav svg {
    max-width: 18px;
    min-width: 18px;
  }

  .partners__slide_main {
    padding: 0 10px;
    width: 100%;
  }
}

@media (min-height: 768px) 
and (max-height: 900px)
and (max-width: 1400px)
and (min-width: 1200px) {
  .partners__swiper {
    &_main {
      max-width: 1400px;
    }
    // &_child {
    // }
  }
}