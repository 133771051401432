.scroll-section {
  height: 100%;
  outline: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    min-height: 100%;

    position: relative;
    overflow: hidden;

    &:not(.hero) {
      display: flex;
      align-items: center;
    }
  }
}
