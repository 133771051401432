@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.advisors {
  background-image: url('../../../assets/images/team/team_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
}
// .advisors__frame {
// }
.advisors__content {
  padding: 35px 18px 119.5px;
}
.advisors__box {
  margin-top: 63px;
  // max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.advisors__swiper {
  display: flex;
  justify-content: space-evenly;
  max-width: 1134px;
  width: 100%;
  overflow: hidden;
}
.advisors__slide {
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  &:first-child .advisors__separator {
    display: none;
  }
  .team-card {
    .team-card__header-block::after {
      background: #ff1fa2;
    }
  }


  .team-card{
    .team-card__text {
        border-top: 1px solid #ff1fa2;
      }
  }
  .team-card{
    .team-card__trapezoid {

        background: #ff1fa2;
    
        &::before,
        &::after {
          border-bottom: 3px solid #ff1fa2;
        }
    
        &::before {
          border-left: 3px solid #ff1fa2;
        }
    
        &::after {
          border-right: 3px solid #ff1fa2;
        }
      }
  }
}
.advisors__separator {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
}
@media (max-width: 1160px) {
  .advisors__content {
    padding-top: 100px;
  }
}
@media (max-width: 700px) {
  .advisors__title span {
    font-size: 42px;
  }
  .advisors__box {
    margin-top: 30px;
    justify-content: center;
  }
  .advisors__swiper {
    max-width: 450px;
    margin: 0;
  }
}
@media (max-width: 521px) {
  .advisors__title span {
    font-size: 30px;
  }
  .advisors__swiper {
    max-width: 280px;
  }
}
@media (max-width: 376px) {
  .advisors__title span {
    font-size: 25px;
  }
  .advisors__swiper {
    max-width: 250px;
  }
}
.advisors__slide:nth-child(odd) {
  display: flex;
  flex-flow: wrap;

    .team-card__header {
      order: 1;
    }

    .team-card__body {
      order: 0;
    }
  }


  //Gradient
  
.advisors-gradient{
  position: absolute;
  bottom: 0px;
  left: 0%;
}

.advisors-gradient__top{
  position: absolute;
  right: 0px;
  top: 0%;
  transform: rotate(180deg);
}


button.advisor__prev.advisor__nav {
  position: absolute;
  left: 0em;
  top: 28em;
}

button.advisor__next.advisor__nav {
  position: absolute;
  right: 0;
  top: 28em;
}
@media (max-width: 1500px) {
  button.advisor__prev.advisor__nav  {
    top: 20.4em;
  }
  
  button.advisor__next.advisor__nav {
    top: 20.4em;
  }
}

@media (max-width: 850px) {
  button.advisor__prev.advisor__nav  {
    top: 25.4em;
  }
  
  button.advisor__next.advisor__nav {
    top: 25.4em;
  }
}