.screen-controller {
    &.-partners-screen {
        #{screenClass(6)} {
            @include setActiveScreen;
        }
    }


    // 
    #{screenClass(6)} {
        .partners__content, .partners__list {
            perspective: 1000px;
        }
        .partners__list > * {
            transform: translate3d(0, 200px, 600px) rotateX(45deg);
            opacity: 0;
        }
        .section-title {
            transition: 1s transform ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
    }
    &.-partners-screen {
        #{screenClass(6)} {
            .section-title, .partners__list > * {
                transform: translate3d(0, 0, 0) rotateX(0);
                opacity: 1;
            }
            @for $i from 1 through 20 {
                .partners__list > :nth-child(#{$i}) {
                    transition: .5s transform #{$i / 5}s ease, .5s opacity #{$i / 5}s;
                }
            }
        }
    }
    // 

    // --------------> FRONT
    &.-works-screen.-between-front-screen {
        #{screenClass(5)} {
            opacity: 0;
            transition: opacity 2s ease;
        }
        #{screenClass(6)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
    }

    // --------------> BACK
    &.-partners-screen.-between-back-screen {
        #{screenClass(5)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
        #{screenClass(6)} {
            opacity: 0 !important;
            transition: opacity 2s ease;
        }
    }


}