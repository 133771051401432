@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.screen-controller {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    // touch-action: none;

    .scroll-section {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        // visibility: hidden;
        // user-select: none;
        // pointer-events: none;
        > * {
            opacity: 0;
        }
    }
}

@import './contentAnimation';