@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.ui-input {
  position: relative;
}
.ui-input__title {
  font: 600 22px/1.1 $clashDisplay;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.03em;

  + .ui-input__field {
    margin-top: 15px;
  }
}
.ui-input__field {
  width: 100%;
  background: #0e0b12;

  input {
    border: none;
    background: none;
    outline: none;
    max-width: 100%;
    width: 100%;
    padding: 26px;

    font: 400 16px/1.21 $plusJakarta;
    letter-spacing: 0.03em;
    color: #fff;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
  }
}
