/* 
  SCSS Variables 
*/
$app-transition: .15s ease-in-out;
// Colors
$clr-black: #141415;

/* 
  Fonts 
*/
$clashDisplay: "Clash Display";
$neueMachina: "Neue Machina";
$plusJakarta: "Plus Jakarta";
$lato: "Lato";

// CSS Variables
:root{
  --index: calc(1vw + 1vh);
}