@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.menu-section {
    background: #000;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.menu-section__content {
    padding: 40px 40px 40px;
    text-align: center;
}

.menu-section__box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.menu-section__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 10;
}

.menu-section__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // justify-content: space-around;
}

.menu-section__item {
    margin-right: 30px;
    margin-left: 30px;
    &:hover {
        cursor: pointer;
    }
}

.menu-section__img {
    transform-origin: center center;
    animation: 20s linear 0s normal none infinite running menu-section__img;
    -webkit-animation: 20s linear 0s normal none infinite running menu-section__img;
    max-width: 100%;
}

@keyframes menu-section__img {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes menu-section__img {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.menu-section__about {
    margin-bottom: 50px;
    margin-left: 150px;
}

.menu-section__mission {
    margin-top: 70px;
}

.menu-section__token {
    margin-bottom: 120px;
}

.menu-section__partners {
    margin-top: 200px;
}

.menu-section__roadmap {
    margin-left: 90px;
    margin-top: -70px;
}

.menu-section__works {
    margin-left: 70px;

}

.menu-section__team {
    margin-left: 80px;
    margin-top: -170px;
}

.menu-section__content__content {
    padding: 100px 40px 100px;
    text-align: center;
}

.menu-section__text-about {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-600%);
}

.menu-section__text-mission {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-500%);
}

.menu-section__text-token {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-500%);
}

.menu-section__text-partners {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-580%);
}

.menu-section__text-roadmap {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-510%);
}

.menu-section__text-works {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-320%);
}

.menu-section__text-team {
    color: #fff;
    text-transform: uppercase;
    justify-content: space-around;
    transform: translateY(-500%);
}

.text {
    font: 700 22px/1.1 $clashDisplay;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    background: linear-gradient(270.07deg,
            #f8fdfc 6.06%,
            #bac1ff 46.91%,
            #ffffff 96.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    --text-fill-color: transparent;
}

@media (max-width: 1380px) {
    .menu-section__team {
        margin-left: 0;
        margin-top: 0;
    }

    .menu-section__partners {
        margin-top: 0;
    }
}

@media (max-width: 1315px) {
    .menu-section__wrap {
        justify-content: center;
    }
}
@media (max-width: 998px) {
    .menu-section {
        display: none;
    }
}