@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.hero {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: rgb(14, 14, 14);
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: radial-gradient(transparent, #000);
    position: absolute;
    left: 0;
    top: 0;
  }

  .hero-content__container {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 901px) and (min-height: 770px){
    display: flex;
    align-items: center;

    .hero-content__container {
      padding-top: 0;
      padding-right: 20px;
    }
    .hero-content {
      margin-top: 90px;
    }
  }
  @media (max-width: 900px){
    padding-top: 50px;
  }
  @media (max-width: 565px){
    padding-top: 77px;
  }
  @media (max-width: 360px){
    padding-top: 40px; 
  }
}
// .hero__ripple {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center;
//   mix-blend-mode: overlay;
//   opacity: 0.5;
//   z-index: 4;
// }

//Gradient
.hero__gradientFirst {
  position: absolute;
  width: 1250px;
  height: 1200px;
  right: 40%;
  top: 100%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.hero__gradientSecond {
  position: absolute;
  width: 1350px;
  height: 1350px;
  top: 110%;
  background: #0011E0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}
.hero__gradientLeft{
  position: absolute;
  width: 1350px;
  height: 1350px;
  bottom: 100%;
  right: 40%;
  background: #0011E0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}
.hero__gradientRight{
  position: absolute;
  width: 1250px;
  height: 1200px;
  left: 30%;
  bottom: 100%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}