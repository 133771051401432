.screen-controller {
    &.-works-screen {
        #{screenClass(5)} {
            @include setActiveScreen;
        }
    }

    // --------------> FRONT
    &.-token-screen.-between-front-screen {
        #{screenClass(4)} {
            opacity: 0;
            transition: opacity 2s ease;
        }
        #{screenClass(5)} {
            opacity: 1;
            transition: 1.75s opacity 0.25s ease;
            @include setActiveScreen;
        }
    }

    // доп стили для анимаций
    #{screenClass(5)} {
        .works__content { perspective: 1000px; }
        .section-title {
            transition: 1s transform ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
        .works-board {
            transform: scale(0.5);
            opacity: 0;
            transition: transform 1s ease, opacity 1s;
        }
        .works__wrapper {
            transition: 1s transform .5s ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
        .works__btn {
            transition: 1s transform 1s ease;
            transform: translate3d(0, 300px, 1000px) rotateX(45deg);
        }
    }
    // 
    &.-works-screen {
        #{screenClass(5)} {
            .section-title, .works-board, .works__wrapper, .works__btn {
                transform: translate3d(0, 0, 0) rotateX(0);
            } 
            .works-board {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
    // 

    // --------------> BACK
    &.-works-screen.-between-back-screen {
        #{screenClass(4)} {
            opacity: 1;
            transition: 1.75s opacity 0.25s ease;
            @include setActiveScreen;
            .token__backgrounds, .token__container {
                transform: none;
            }
        }
        #{screenClass(5)} {
            opacity: 0 !important;
            transition: opacity 2s ease;
        }

    }


}