.screen-controller {
    &.-roadmap-screen {
        #{screenClass(9)} {
            @include setActiveScreen;
        }
    }


    // // 
    #{screenClass(9)} {
        .roadmap__content {
            perspective: 1000px;
        }
        .roadmap__slider {
            transition: 1s transform ease, opacity 1s;
            transform: translate3d(0, 200px, 600px) rotateX(45deg);
            opacity: 0;
        }
    }
    &.-roadmap-screen {
        #{screenClass(9)} {
            .roadmap__slider {
                transform: translate3d(0, 0, 0) rotateX(0);
                opacity: 1;
            }
        }
    }
    // // 

    // --------------> FRONT
    &.-advisors-screen.-between-front-screen {
        #{screenClass(8)} {
            opacity: 0;
            transition: opacity 2s ease;
        }
        #{screenClass(9)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
    }

    // --------------> BACK
    &.-roadmap-screen.-between-back-screen {
        #{screenClass(8)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
        #{screenClass(9)} {
            opacity: 0 !important;
            transition: opacity 2s ease;
        }
    }

    &.-roadmap-screen.-between-back-screen {
        #{screenClass(8)} {
            opacity: 1;
            transition: 1s opacity 1s ease;
            @include setActiveScreen;
        }
        #{screenClass(9)} {
            opacity: 0 !important;
            transition: opacity 2s ease;
        }
    }
}