.screen-controller {
    #{screenClass(3)} {
        .target__container {
            transform: matrix3d(1,0,0.00,0,0.00,1,0.00,-0.02,0,0,1,0,0,0,0,1);
        }
    }
    &.-token-screen {
        #{screenClass(4)} {
            @include setActiveScreen;
            .token__backgrounds, .token__container {
                transform: none;
            }
            .token__backgrounds > div {
                > :nth-child(2) {
                    animation: changeLayer 1.25s ease infinite;
                  }
                  > :nth-child(3) {
                    animation: changeLayer2 1.25s ease infinite;
                  }
                  > :nth-child(4) {
                    animation: 1.25s changeLayer .25s ease infinite;
                  }
            }
        }
    }

    #{screenClass(3)} {
        .target__container {
            transform-origin: center top;
        }
    }
    #{screenClass(4)} {
        .token__backgrounds, .token__container {
            transform: matrix3d(1,0,0.00,0,0.00,1,0.00,-0.02,0,0,1,0,0,0,0,1);
            transform-origin: center top;
        }
    }

    // --------------> FRONT
    &.-target-screen.-between-front-screen {
        #{screenClass(3)} {
            .target__container {
                opacity: 0;
                transition: transform 1.5s ease, opacity 1.5s ease;
                transform: matrix3d(1,0,0.00,0,0.00,1,0.00,-0.02,0,0,1,0,0,0,0,1);
            }
   
        }
        #{screenClass(4)} {
            opacity: 1;
            @include setActiveScreen;
            .token__backgrounds, .token__container {
                transition: 1.5s transform ease, 1.5s opacity ease;
                transform: none;
            }
        }
    }
    // 

    #{screenClass(4)} {
        .token__logos {
            transform:  translate(-50%, -50%) scale(0) rotate(280deg);
            transition: 1.5s transform .5s ease;
        }
        .token__btn {
            transform: scale(0.5);
            opacity: 0;
            display: inline-block;
            transition: transform 1s ease, opacity 1s;
        }
    }

    &.-token-screen {
        #{screenClass(4)} {
            .token__logos {
                transform:  translate(-50%, -50%) scale(1) rotate(0deg);
            }
            .token__btn {
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    
    // --------------> BACK
    &.-token-screen.-between-back-screen {
        #{screenClass(3)} {
            .target__container {
                transition: 1.5s transform 0.5s ease, 1.5s opacity 0.5s ease;
                transform: matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1);
            }
            @include setActiveScreen;
        }
        #{screenClass(4)} {
            opacity: 0;
            @include setActiveScreen;
            .token__backgrounds, .token__container {
                transition: 1.5s transform ease, 1.5s opacity ease;
                transform: matrix3d(1,0,0.00,0,0.00,1,0.00,-0.02,0,0,1,0,0,0,0,1);
            }
        }
    }
    // 
}


