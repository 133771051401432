@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.ui-select {
  position: relative;
}
.ui-select__title {
  font: 600 22px/1.1 $clashDisplay;
  color: #fff;
  text-transform: inherit;
  letter-spacing: 0.03em;

  + .ui-select__field {
    margin-top: 15px;
  }
}
.ui-select__field {
  width: 100%;
  background: #0e0b12;
  position: relative;
}
.ui-select__field-btn {
  width: 100%;
  padding: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font: 400 16px/1.21 $plusJakarta;
    color: #fff;
  }
  .ui-select__field-arrow {
    width: 19px;
    flex-shrink: 0;
    transition: transform .15s ease-in;
  }
}
.ui-select__field-select {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 20;
  background: #0e0b12;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

// item
.select-item {
  width: 100%;
  cursor: pointer;
}

// Animation
.ui-select.active {
  .ui-select__field-arrow {
    transform: scaleY(-1);
  }
}
.ui-select-field-enter {
  opacity: 0;
  transform: scaleY(0);
}
.ui-select-field-enter-active {
  transition: 0.15s ease-in;
  transition-property: opacity, transform;
  transform-origin: center top;
  opacity: 1;
  transform: scaleY(1);
}
.ui-select-field-exit {
  opacity: 1;
  transform: scaleY(1);
}
.ui-select-field-exit-active {
  transition: 0.15s ease-in;
  transition-property: opacity, transform;
  transform-origin: center top;
  opacity: 0;
  transform: scaleY(0);
}
