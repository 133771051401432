@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.target {
  min-height: 100vh;
}
// .target__container {
//   position: relative;
// }

.target__lightning {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    margin-bottom: 50px;
  }
}
.target__frame {
  padding: 46.5px 0 68.5px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  // padding: 0;
  // margin-top: 20px;

  // .content-frame__background {
  //   z-index: 8;
  // }
}
.target__content {
  padding: 75px 30px 100px;
  max-width: 1210px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // margin-top: 150px;
}
.target__title {
  position: relative;
  z-index: 10;
  margin-bottom: 88px;
}
// List
.target__list {
  position: relative;
  z-index: 8;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.target__list-col {
  + .target__list-col {
    margin-left: 310px;
  }
}
// Card
.target-card {
  position: relative;
  max-width: 337.5px;
  min-height: 259px;
  padding: 55px 10px 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  &_1 {
    clip-path: url(#card1-clippy);
    // margin-left: 27px;
    background: linear-gradient(180deg, rgba(41, 32, 35, 0.7) 30.99%,rgba(5, 8, 9, 0.7) 67.54%);
  }
  &_2 {
    clip-path: url(#card2-clippy);
    // margin-top: 26.4px;
    background: linear-gradient(180deg, rgba(5, 8, 9, 0.7) 30.99%, rgba(41, 32, 35, 0.7) 67.54%);
  }
}
.target-card__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  // background: rgba(#141529, 0.4);
  background-image: url('../../../assets/images/target/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    width: 100%;
    height: 100%;
  }
}
.target-card__content {
  position: relative;
  z-index: 5;
  margin-left: 30px;
  margin-right: 30px;
}
.target-card__title {
  font: 600 22px/1.405 $clashDisplay;
  color: #fff;
  text-transform: capitalize;
}
.target-card__text {
  max-width: 240px;
  margin-top: 20px;
  font: 400 14px/1.21 $lato;
  color: rgba(#fff, 0.5);
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0.03em;
}
// Hand
.target-hand {
  position: absolute;
  z-index: 8;
  left: 50%;
  bottom: -140px;
  transform: translateX(-42%);
  max-width: 500px;
  overflow: hidden;
}
.target-hand__container {
  position: relative;
}
.target-hand__iphone {
  position: relative;

  img {
    width: 100%;
  }
}
.target-hand__video {
  position: absolute;
  left: 50%;
  top: 1.6%;
  transform: translateX(-63.8%);
  border-radius: 12% / 5%;
  overflow: hidden;

  width: 56%;
  height: 87%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }
}
.target-hand__quiff {
  position: absolute;
  width: 30%;
  left: 50%;
  top: 1%;
  transform: translateX(-75%);
}
.target-hand__finger {
  position: absolute;
  right: 0;
  // bottom: 0;
  width: 36.7%;
}

@media (max-width: 991px) {
  // .target__frame {
  //   .content-frame__keyboard {
  //     top: 720px;
  //     bottom: auto;
  //   }
  // }
  .target-hand {
    max-width: 380px;
    // bottom: 0;
  }
  .target__list-col {
    + .target__list-col {
      margin-left: 200px;
    }
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .target-card__title {
    font-size: 19px;
  }
  .target-card {
    min-height: auto;
    padding: 40px 20px 45px;
  }
}
@media (max-width: 865px) {
  .target__content {
    padding-left: 18px;
    padding-right: 18px;
  }
  .target__card {
    margin-top: 100px;
  }
  .target-card_1 {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .target__frame {
    .content-frame__keyboard {
      bottom: 4%;
    }
  }
  .target {
    .content-frame__background {
      max-height: 811px;
      z-index: 2;
    }
  }
  .target__content {
    padding-top: 127px;
  }
  .target__title {
    margin: 0 auto;
    width: min-content;
  }
  .target__lightning {
    display: none;
  }
  .target__list {
    flex-direction: column;
    align-items: center;
    margin-bottom: 130px;
    margin-top: 50px;
  }
  .target__list-col {
    + .target__list-col {
      margin-top: 50px;
      margin-left: 0;
    }
  }
  .target-card {
    &_2 {
      padding: 30px 10px 60px;
    }
    &_3 {
      margin-top: 26.4px;
    }
  }
  // Hand
  .target-hand {
    position: relative;
    left: auto;
    bottom: auto;
    transform: translateX(8%);
    margin: 50px auto 0;
    padding-top: 50px;
  }
}
@media (max-width: 465px) {
  .target__frame {
    padding-bottom: 20px;
  }

  .target__content {
    padding: 127px 5px 80px;
  }

  .target-hand {
    transform: translateX(0%);
    margin: 9px -18px 0;
    align-self: flex-end;
  }
  .target-hand__finger {
    display: none;
  }
  .target-hand__video {
    left: 50%;
    top: 1.2%;
    transform: translateX(-53%);
    border-radius: 12% / 5%;

    width: 63%;
    height: 58%;
  }
  .target-hand__quiff {
    transform: translateX(-55%);
  }
  
  .target-card__title {
    margin-top: 30px;
  }

  .target__list {
    margin-top: -60%;
  }
}
@media (max-width: 415px) {
  .target__list {
    margin-top: -80%;
  }
}
@media (max-width: 376px) {
  .target {
    .content-frame__background {
      max-height: 770px;
    }
  }
}
@media (max-width: 345px) {
  .target {
    .content-frame__background {
      max-height: 700px;
    }
  }
}
/* 
  Animation
*/
.target-card {
  opacity: 0;
  transition-duration: 0.3s;
}
.target-hand__container {
  transform: translate(50%, 100%);
  transition-duration: 0.3s;
}
// .target.active {
//   .target-card {
//     opacity: 1;
//     transition: opacity 1s ease-in-out;

//     &_1 {
//       transition-delay: animDelay(0.2);
//     }
//     &_2 {
//       transition-delay: animDelay();
//     }
//     &_3 {
//       transition-delay: animDelay(0.35);
//     }
//   }
//   .target-hand__container {
//     transform: translateY(0);
//     transition: transform 1s ease-in-out;
//   }
// }

.gradientFirst {
  position: absolute;
  width: 1250px;
  height: 1200px;
  right: 1057px;
  top: 900px;
  background: linear-gradient(193.62deg, #ff1fa2 33.87%, #ffc0ff 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.gradientSecond {
  position: absolute;
  width: 1750px;
  height: 1750px;
  top: 140%;
  background: #0011e0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.target-gradient {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 1;
}

@media (max-width: 1600px) {
  .target__content {
    padding-bottom: 75px;
    padding-bottom: 70px;
  }

  .target-hand {
    max-width: 400px;
    bottom: -100px;
  }

  .target__title {
    margin-bottom: 30px;
  }
}