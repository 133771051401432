@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.menu {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 170;
  background: #040308;
  padding-top: 120px;
  flex-direction: column;
  align-items: center;
  transform: translateX(100%);
  visibility: hidden;
  transition: 0.25s ease-out !important;
  transition-property: visibility, transform !important;

  @media (max-width: 998px){
    display: flex;
  }
}
.menu__signup {
  font-size: 13.8px;
}
.menu__list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu__link {
  font: 600 18px/1.1 $clashDisplay;
  letter-spacing: 0.225em;
  text-transform: uppercase;

  background: linear-gradient(
    270.07deg,
    #f8fdfc 6.06%,
    #bac1ff 46.91%,
    #ffffff 96.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  --text-fill-color: transparent;

  transform: rotate(0.21deg);

  + .menu__link {
    margin-top: 50px;
  }
}
.home-page.menu-active .menu {
  visibility: visible;
  transform: translate(0);
}

@media (max-width: 375px){
  .menu {
    padding-top: 75px;
  }
  .menu__list {
    margin-top: 35px;
  }
  .menu__link + .menu__link {
    margin-top: 35px;
  }
}

.menu__items{
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
  margin-top: 100px;
}
.menu__links{
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  align-items: center;
  fill: #fff;
  fill-opacity: 0.4;
}

.menu__links:hover,
.menu__links:focus {
  fill-opacity: 1;
}

.menu__logo {
  max-width: 85px;
  overflow: hidden;
  margin-top: -5%;
  margin-left: -60%;
  padding-bottom: 5%;
  &,
  >img {
    width: 100%;
  }
}

@media (max-width: 625px) {
  .menu__logo {
    padding-bottom: 10%;
  }
}

@media (max-width: 500px) {
  .menu__logo {
    padding-bottom: 20%;
  }
}


// Gradient
.menu__gradientFirst {
  position: absolute;
  width: 1250px;
  height: 1200px;
  right: 40%;
  top: 90%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.menu__gradientSecond {
  position: absolute;
  width: 1350px;
  height: 1350px;
  top: 110%;
  background: #0011E0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.menu__gradientLeft {
  position: absolute;
  width: 1350px;
  height: 1350px;
  bottom: 100%;
  right: 40%;
  background: #0011E0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.menu__gradientRight {
  position: absolute;
  width: 1250px;
  height: 1200px;
  left: 30%;
  bottom: 100%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}