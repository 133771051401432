@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.scene {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0; left: 0;
    z-index: 115;
    pointer-events: none;
    user-select: none;

    > canvas {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 991px){
    .scene {
        z-index: 1;
    }
}

.dg.ac {
    z-index: 1001 !important;
}