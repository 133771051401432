@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.content-frame {
  padding: 46.5px 0 68.5px;
  display: flex;
  flex-direction: column;
}
.content-frame__wrapper {
  position: relative;
  flex-grow: 1;
}
.content-frame__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.content-frame__frame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &[data-mobile] {
    display: none;
  }

  .svg-stroke {
    stroke: #c7c7c7;
  }
  .svg-stroke-frame {
    stroke: #515151;
  }
  .svg-stroke-frame__borders {
    display: none;
  }
}
.content-frame__keyboard {
  max-width: 94px;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 20;
  transform: translate(-50%, 40%);
  border-radius: 50%;
  backdrop-filter: blur(10px);

  .keyboard-arrow {
    fill: #fff;
    fill-opacity: 0.21;
    transition: fill-opacity .08s ease-in;

    &.active {
      &:hover {
        cursor: pointer;
        fill-opacity: 1;
      }
    }
  }

  .keyboard-up.active,
  .keyboard-down.active,
  .keyboard-left.active,
  .keyboard-right.active {
      stroke: #fff;
      stroke-width: 1px;
  }
}
.content-frame.borders {
  .svg-stroke-frame__borders {
    display: block;
  }
}
.content-frame__content {
  position: relative;
  z-index: 4;
}
@media (max-width: 575px){
  .content-frame__frame {
    &[data-mobile] {
      display: block;
    }
    &[data-desktop] {
      display: none;
    }
  }
  .content-frame__keyboard {
    max-width: 80px;
  }
}

@media (max-width: 465px){
  .content-frame {
    margin: 0 -12px;
  }
}