@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.roadmap {
  overflow: hidden;
  background: #0A0A0B;
}
.roadmap__content {
  padding: 136.5px 0 50px;
  display: flex;
}
.roadmap__desc {
  flex: 0 0 45%;
  position: relative;
  z-index: 5;
  padding-left: 105px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.roadmap__title {
  justify-content: flex-start;
}
.roadmap__text {
  max-width: 365px;
  margin-top: 25px;
  font: 500 14px/1.21 $plusJakarta;
  letter-spacing: 0.03em;
  text-transform: none;
  text-align: left;
  color: rgba(209, 209, 209, 0.87);
}
.roadmap__slider {
  max-width: 832px;
  width: 100%;
  position: relative;
  z-index: 3;
}
.roadmap__swiper {
  max-width: 100%;
  width: 100%;
  overflow: visible;
}
.gradientFirst {
  position: absolute;
  width: 1250px;
  height: 1200px;
  right: 1057px;
  top: 900px;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.gradientSecond {
  position: absolute;
  width: 1750px;
  height: 1750px;
  top: 140%;
  background: #0011E0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

@media (max-width: 991px) {
  .gradientFirst {
      top: 700px;
      right: 800px;
    }
  
  .gradientSecond {
      top: 1025px;
    }
  }
  @media (max-width: 656px) {
    .gradientFirst {
      top: 700px;
      right: 600px;
    }
    
    .gradientSecond {
      top: 1125px;
    }
  }

  @media (max-width: 580px) {
    .gradientFirst {
      width: 665px;
      height: 322px;
      top: 1461px;
      right: -93px;
      transform: rotate(210deg);
      opacity: .3;
    }

    .gradientSecond {
        display: none;
    }
    .roadmap-swiper-nav-buttons {
      top: 60%;
    }
  }


// Card
.roadmap__slide {
  padding: 0 52px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  height: auto;
  display: flex;

  &.swiper-slide-active {
    &,
    & ~ .roadmap__slide {
      opacity: 1;
    }
  }
}
.roadmap-card {
  width: 100%;
  height: auto;
  padding: 5px 91px 1px 91px;
  position: relative;
  clip-path: url(#roadmap-frame-clippy);
  background: transparent;
  background-image: url('../../../assets/images/roadmap/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}
.roadmap-card__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.roadmap-card__content {
  position: relative;
  z-index: 5;
}
.roadmap-card__image {
  max-width: 100%;
  margin-left: 100px;
}
.roadmap-card__title {
  // margin-top: 43px;
  display: flex;
  justify-content: flex-start;
  font: 600 25px/1.1 $clashDisplay;
  letter-spacing: 0.47em;
  text-transform: uppercase;
  background: linear-gradient(
    270.07deg,
    #f8fdfc 6.06%,
    #bac1ff 46.91%,
    #ffffff 96.31%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  --text-fill-color: transparent;
}
.roadmap-card__list {
  padding-left: 18px;
  margin-top: 21.35px;
  list-style: initial;
  max-width: 490px;
  margin-bottom: 50px;
}
.roadmap-card__list-item {
  font: 500 14px/1.21 $plusJakarta;
  letter-spacing: 0.03em;
  color: rgba(209, 209, 209, 0.75);
}

.roadmap-swiper-nav-buttons {
  position: absolute;
  bottom: 50px;
  right: 80px;
  display: flex;
  justify-content: end;
}

.keyboard-arrow-roadmap {
  position: relative;
  z-index: 100;
  opacity: .3;

  transition: all .1s linear;

  &:hover {
    opacity: 1;
  }

  &+& {
    margin-left: 40px;
  }
}

@media (max-width: 1220px) {
  .roadmap__desc {
    flex-basis: 35%;
    padding-left: 60px;
  }
  .roadmap__slide {
    padding: 0 30px;
    padding-left: 80px;
  }
}
@media (max-width: 1080px) {
  .roadmap__title h2 {
    font-size: 38px;
  }
}
@media (max-width: 991px) {
  .roadmap-card {
    padding: 40px 50px 1px;
  }
  .roadmap-card__title {
    margin-top: 25px;
  }
  .roadmap-card__content {
    max-width: 350px;
  }
  .roadmap-card__img {
    height: auto;
  }
  .roadmap__content {
    padding-bottom: 100px;
  }
}
@media (max-width: 865px) {
  .roadmap__desc {
    max-width: 100%;
    flex: 0 0 auto;
    text-align: center;
    align-items: center;
    padding-left: 65px;
    padding-right: 25px;
  }
  .roadmap__content {
    padding: 114px 40px 134px 0;
    flex-direction: column;
  }
  .roadmap__slider {
    margin-top: 30px;
    
  }
  .roadmap__slide {
    justify-content: center;
  }
  .roadmap-card__content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .roadmap__text{
    text-align: center;
  }
  .roadmap-card__image{
    margin: auto;
  }
  .roadmap-swiper-nav-buttons {
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-between;
    z-index: 50;
    pointer-events: none;
  }
  .keyboard-arrow-roadmap {
    position: relative;
    display: block;
    z-index: 100;
    pointer-events: visible;
    opacity: .6;

    
  }

}
@media (max-width: 656px) {
  .roadmap__slide {
    padding: 0 15px;
    padding-left: 50px;
    display: flex;
    align-items: flex-start;
  }
  .roadmap__content {
      padding: 114px 40px 70px 0;
      flex-direction: column;
  }
  .roadmap-card {
    padding: 26px 23px;
  }
  .roadmap-card__title {
    margin-top: 20px;
  }
}
@media (max-width: 580px) {
  .roadmap-swiper-nav-buttons {
    top: 53%;
  }

  .roadmap__slide {
    padding-left: 35px;
    padding-right: 0;
  }
}
@media (max-width: 465px) {
  .roadmap__title h2 {
    font-size: 35px;
  }
  .roadmap__swiper {
    position: relative;
  }

  .roadmap__slide {
    padding-left: 40px;
  }
  .roadmap-card {
    padding: 0 20px 0px 20px;
  }
    .roadmap-card__img {
      width: 70%;
      height: 50%;
      margin-left: auto;
      margin-right: auto;
    }
}
@media (max-width: 376px) {
  .roadmap-card__title {
    margin-top: 13px;
  }
  .roadmap-card__list {
    margin-top: 13px;
  }
  .roadmap-card__list-item {
    font-size: 12px;
  }
}
.roadmap-gradient{
  position: absolute;
  bottom: -10%;
  z-index: 1;
}

.roadmap-gradient__top{
  position: absolute;
  top: -10%;
  right: 0px;
  transform: rotate(180deg);
}

//Button
.roadmap__btn{
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  transform: translate(100%, -50%);
  }

    @media (max-width: 991px) {
      .roadmap__btn {
          transform: translate(50%, -50%);
        }
    }
        @media (max-width: 865px) {
          .roadmap-card__list{
            margin-bottom: 30px;
          }
          .roadmap__btn {
            transform: translate(70%, -50%);
          }
        }
@media (max-width: 656px) {
  .roadmap__btn {
    transform: translate(50%, -50%);
  }
}
@media (max-width: 600px) {
  .roadmap__btn {
    display: none;
  }
  .roadmap__card:last-child{
    left: 40px;
  }
}


@media (max-width: 1600px) {
  .roadmap__content {
    padding-top: 50px;
  }
}


