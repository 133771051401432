@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.token {
  background: linear-gradient(to Bottom, transparent 35%, #000 100%);
  // background: #ff1fa2;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.token__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.token__portal{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.token__backgrounds {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    overflow: hidden;
    margin: 0 auto;

    > * {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
@keyframes changeLayer {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes changeLayer2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.token__content {
  padding: 185px 40px 100px;
  text-align: center;
}
.token__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.token__title {
  position: relative;
  z-index: 4;
}
.token__text {
  position: relative;
  z-index: 4;
  margin-top: 225px;
  max-width: 342px;
  font: 500 14px/1.21 $plusJakarta;
  text-align: center;
  letter-spacing: 0.03em;
  color: #d1d1d1;
}
.token__logos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  max-width: 185px;
  width: 100%;
}
.token__btn {
  margin: 20px auto 0;
}
@media (max-width: 991px) {
  .token__content {
    padding-top: 250px;
    padding-bottom: 150px;
  }
  .token__backgrounds {
    top: auto;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    img {
      min-width: 850px;
    }
  }
  .token__logos {
    max-width: 200px;
  }
}
@media (max-width: 768px) {
  .token__content {
    padding-top: 250px;
    padding-bottom: 170px;
  }
  .token__backgrounds {
    img {
      min-width: 850px;
      height: 860px;
    }
  }
  .token__title {
    max-width: min-content;
  }
}
@media (max-width: 575px) {
  .token__content {
    padding-top: 220px;
    padding-bottom: 80px;
  }
  .token__backgrounds {
    img {
      min-width: 620px;
      height: 600px;
    }
  }
  .token__text {
    margin-top: 152px;
  }
  .token__logos {
    max-width: 216px;
  }
  .token__btn {
    margin-top: 27px;
    font-size: 13.9;
  }
}
@media (max-width: 465px) {
  .token__content {
    padding-top: 180px;
  }
  .token__title span {
    font-size: 40px;
  }
  .token__logos {
    max-width: 185px;
  }
  .token__text {
    margin-top: 120px;
  }
}
@media (max-width: 375px) {
  .token__content {
    padding-top: 220px;
    padding-bottom: 140px;
  }
  .token__background {
    img {
      min-width: 600px;
      height: 720px;
    }
  }
  .token__text {
    margin-top: 120px;
    font-size: 12.5px;
  }
  .token__btn {
    font-size: 13px;
  }
}

.token__gradientLeft {
  position: absolute;
  z-index: 1;
  width: 1350px;
  height: 1350px;
  top: -150%;
  right: 30%;
  background: #0011e0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.token__gradientRight {
  position: absolute;
  z-index: 1;
  width: 1250px;
  height: 1200px;
  left: 90%;
  top: -90%;
  background: linear-gradient(193.62deg, #ff1fa2 33.87%, #ffc0ff 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.token-gradient {
  position: absolute;
  bottom: 0px;
  z-index: 2;
}

.token-gradient__top {
  position: absolute;
  top: -10%;
  right: 0px;
  transform: rotate(90deg);
  z-index: 2;
}

@media (max-width: 1600px) {
  .token__content {
    padding-top: 110px;
  }
}