@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.getintouch__title {
  text-align: center;
  letter-spacing: 0.47em;
  padding-left: 0.47em;
}
.getintouch__role-item {
  display: flex;
  align-items: center;

  svg {
    max-width: 20px;
    max-height: 20px;
    width: 100%;
    flex-shrink: 0;
  }

  p {
    margin-left: 10px;
    font: 400 16px/1.21 $plusJakarta;
    color: rgba(#fff, 0.2);
    white-space: nowrap;
  }
}
.select-item {
  transition: background .15s ease-in;
  .getintouch__role-item {
    padding: 20px 26px;
  }
  &:hover {
    background: lighten(#0E0B12, 2%)
  }
}
.ui-select__field-btn p {
  color: #FFF;
}
@media (max-width: 991px){
  .select-item .getintouch__role-item {
    padding: 20px;
  }
}
@media (max-width: 768px){
  .getintouch__role-item {
    p {
      font-size: 13px;
    }
    svg {
      max-width: 16px;
      max-height: 16px;
    }
  }
}
@media (max-width: 565px){
  .getintouch__title {
    max-width: 320px;
    margin: 0 auto;
  }
  .select-item .getintouch__role-item {
    padding: 16px;
  }
}
@media (max-width: 390px){
  .getintouch__title {
    max-width: 250px;
  }
}
@media (max-width: 375px){
  .select-item .getintouch__role-item {
    padding: 13px;
  }
}