@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.section-title {
  display: flex;
  justify-content: center;
}
.section-title h2 {
  font: 600 45px/1.1 $clashDisplay;
  letter-spacing: 0.47em;
  margin-right: -.47em;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(270.07deg, #f8fdfc 6.06%, #bac1ff 46.91%, #ffffff 96.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 991px){
    font-size: 35px;
  }

  @media (max-width: 376px){
    font-size: 35px;
  }
}
