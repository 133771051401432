@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.about {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  //  background-image: url('../../../assets/images/about/about_bg.png');
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: center;
}

.about__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // z-index: 2;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    // filter: grayscale(1);
  }
}

.about__wrapper {
  position: relative;
  z-index: 5;
}

.about__content {
  padding: 130px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1199px) {
  .about__title-block {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .about__content {
    padding: 80px 80px;
  }

  .about__title-block {
    margin-bottom: -60px;
  }
}

@media (max-width: 768px) {
  .about__title-block {
    margin-top: 30px;
    margin-bottom: -50px;
  }

  .about__frame {
    margin-top: 20px;
    .content-frame__keyboard {
      margin-top: 50px;
      bottom: 5%;
      
    }
  }

  .about {
    .content-frame__background {
      max-height: 740px;
      z-index: 2;
    }
  }
}

.about__title {
  margin-top: -80px;
}

.about__gradientFirst {
  position: absolute;
  z-index: 0;
  width: 1250px;
  height: 1200px;
  right: 90%;
  top: 130%;
  background: #0011E0;
  filter: blur(400px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about__gradientSecond {
  position: absolute;
  z-index: 0;
  width: 1350px;
  height: 1350px;
  top: 120%;
  left: 80%;
  background: linear-gradient(193.62deg, #FF1FA2 33.87%, #FFC0FF 79.93%);
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

.about-gradient {
  position: absolute;
  bottom: 0px;
  z-index: 2;
  left: 0px;
}

.about-gradient__top {
  position: absolute;
  top: -10%;
  right: 0px;
  transform: rotate(90deg);
  z-index: 2;
}

.about__frame {
  padding: 46.5px 0 68.5px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  // padding: 0;
  

  .content-frame__background {
    z-index: 0;
  }
}

@media (max-width: 991px) {
  // .about__frame {
  //   .content-frame__keyboard {
  //     top: 90%;
  //   }
  // }

}

@media (max-width: 575px){
  .about__frame {
    padding-bottom: 10px;
  }
  .about__content {
    padding-bottom: 200px;
  }
}

@media (max-width: 376px) {
  .about__content {
    padding: 120px 10px 200px;
  }

  .about {
    .content-frame__background {
      max-height: 770px;
    }
  }
}

@media (max-width: 345px) {
  .about {
    .content-frame__background {
      max-height: 700px;
    }
  }
}

.about-svg-second {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

@media (max-width: 1600px) {
  .about__content {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}