@import 'styles/mixins.scss';
@import 'styles/vars.scss';

// .partners {
// }
.partners-card__partner:hover {
  opacity: 0.5;
}
.partners__content {
  max-height: 100%;
  padding: 40px 80px 50px;
}
.partners__title {
  margin-bottom: -50px;
}
// .partners__title-slide {
// }
// Card
// .roadmap-frame{
//   padding: 0;
// }
.partners-card__back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(#120d1e, 0.35);
  clip-path: url(#partner-frame-clippy);
}
.partners-card {
  height: 140px;
  max-width: 200px;
  flex: 0 0 25%;
  position: relative;
  display: flex;

  & {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.partners-card__card {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.partners-card__partner {
  position: relative;
  z-index: 6;
  width: 200px;
  height: auto;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
// .partners__list{
//   margin-right: 30px;
// }
// Mod
.partners-card {
  @for $i from 1 through 8 {
    &:nth-child(8n + #{$i}) {
      .partners-card__back {
        background-image: url('../../../assets/images/partners/back/back_0#{$i}.png');
      }
    }
  }
}
@media (max-width: 1180px) {
  .partners__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .partners__title {
    margin-top: 60px;
  }
}
@media (max-width: 991px) {
  .partners-card {
    flex-basis: 33.333333%;
  }
}
@media (max-width: 768px) {
  .partners-card {
    flex-basis: 50%;
  }
  .partners__content {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// @media (max-width: 620px){
//   .partners__list{
//     width: 450px;

//   }
//   .partners-card{
//     width: 70%;
//   }
//   .partners__slide{
// display: flex;
//   width: 514px;
//   justify-content: center;
//   }
// }

@media (max-width: 620px) {
  .partners__labels {
    margin-top: 17px;
    margin-right: 5px;
  }
  .partners__title-slide span {
    font-size: 35px;
    margin-bottom: 50px;
  }
  .partners__list {
    width: 350px;
    justify-content: center;
    margin-right: 15px;
    justify-content: space-evenly;
  }
  // .partners-card {
  //   width: 60%;
  // }
  .partners__slide {
    display: flex;
    width: 400px;
    justify-content: center;
  }
  .partners__content {
    padding-left: 5px;
    padding-right: 5px;
  }
  .partners-card {
    height: 100px;
    max-width: 150px;
    flex: 0 0 25%;
    position: relative;
    display: flex;

    &:not(:last-child) {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
        .partners__frame {
          .content-frame__background {
            display: none;
          }
        }
}

@media (max-width: 465px) {
  .partners__title-slide span {
    font-size: 26px;
    margin-bottom: 50px;
  }
  .partners__slide {
    display: flex;
    width: 350px;
    justify-content: center;
  }
  .partners__list {
    width: 300px;
    justify-content: center;
    margin-right: 15px;
    justify-content: space-between;
  }
  .partners-card {
    width: 100px;
  }
  .partners__title {
    margin-top: 20px;
    margin-bottom: -30px;
  }
  // .partners__content {
  //   padding: 110px 80px 70px;
  // }
  .partners__content {
    padding: 120px 10px 70px;
  }
  .partners__frame {
    .content-frame__background {
      display: none;
    }
  }
}

@media (max-width: 420px) {
  .partners__title {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .partners__slide {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .partners__list {
    width: 100%;
    margin-right: 15px;
    margin-right: 0;
    justify-content: space-between;
  }
  .partners-card {
    width: 48%;
    max-width: 200px;
  }
}
@media (max-width: 375px) {
  .partners__content {
    padding: 135px 15px 60px;
  }
  .partners__title-slide span {
    font-size: 28px;
  }
}

.partners-gradientFirst {
  position: absolute;
  width: 1250px;
  height: 1200px;
  right: 40%;
  top: 100%;
  background: linear-gradient(193.62deg, #ff1fa2 33.87%, #ffc0ff 79.93%);
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.partners-gradientSecond {
  position: absolute;
  width: 1350px;
  height: 1350px;
  top: 110%;
  background: #0011e0;
  filter: blur(260px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}
.partners-gradientCard {
  position: absolute;
  width: 100px;
  height: 197px;
  right: 63%;
  top: 15%;
  background: #cb35ff;
  filter: blur(60px);
  transform: matrix(1, 0.02, -0.04, 1, 0, 0);
}

@media (max-width: 1600px) {

  .partners__slider {
    margin: 40px 0;
    
  }
  .partners-card {
    height: auto;
    max-width: 145px;
  }

  .partners-card {
    height: auto;
    max-width: 130px;

    & {
      margin: 0 10px 10px 0;
    }
  }

  .partners__title-slide {
    margin-top: 30px;
  }

  .partners__slider {
    max-width: 88%;
    margin: 60px auto 100px;
    
  }

  .partners__slide {
    padding: 0;
  }

  .partners__content {
    padding: 0;
    padding-top: 20px;
    padding-bottom: -120px;
  }
}